/* eslint-disable tree-shaking/no-side-effects-in-initialization */
import { gql } from "@apollo/client";
import {
  GLOBAL_ORG_ID,
  GLOBAL_ORG_SHARING_ENVIRONMENTS,
  OrgId,
  RESERVED_ORG_IDS,
  getProductionEnvironment,
} from "@hex/common";

import { safeLocalStorage } from "./util/browserStorage.js";

gql`
  fragment BasicOrgFragment on Org {
    id
    displayName
  }
`;

gql`
  query OrgsClient {
    orgsClient @client {
      orgs {
        id
        ...BasicOrgFragment
      }
      loaded
    }
  }
`;

gql`
  fragment BasicOrgFragmentForPicker on User {
    id
    email
    org {
      id
      ...BasicOrgFragment
    }
  }
`;

gql`
  fragment WorkspacePickerFragment on User {
    id
    orgRole
    email
    org {
      id
      ...BasicOrgFragment
      userCount
    }
  }
`;

gql`
  query OrgsPickerClient {
    orgPickerClient @client {
      authenticatedUsers {
        id
        ...WorkspacePickerFragment
      }
      unauthenticatedUsers {
        id
        ...BasicOrgFragmentForPicker
      }
      loaded
    }
  }
`;

export const getUrlOrg = (): OrgId | undefined => {
  const potentialOrgName = window.location.pathname.split("/")[1];

  // Filter non-org base paths
  const isBaseRoute =
    potentialOrgName != null && RESERVED_ORG_IDS.includes(potentialOrgName);

  return potentialOrgName != null && potentialOrgName !== "" && !isBaseRoute
    ? (potentialOrgName as OrgId)
    : undefined;
};

export const LAST_ORG_STORAGE_KEY = "lastOrganization";

// Avoid using this function directly, and instead use the `ORG_ID` singleton. This is
// to avoid the bug addressed here: https://github.com/hex-inc/hex/pull/11165
const getCurrentOrg = (): OrgId => {
  const orgId = getUrlOrg() ?? safeLocalStorage.getItem(LAST_ORG_STORAGE_KEY);

  if (orgId) {
    safeLocalStorage.setItem(LAST_ORG_STORAGE_KEY, orgId);
  }

  return (orgId ?? GLOBAL_ORG_ID) as OrgId;
};

export const ORG_ID = getCurrentOrg();

const environment = getProductionEnvironment(window.location.hostname);
// Temporary hack until we remove global org. For single-tenant/self-hosted
// enable org sharing even in the global org
export const ORG_SHARING_ENABLED =
  ORG_ID !== GLOBAL_ORG_ID ||
  GLOBAL_ORG_SHARING_ENVIRONMENTS.includes(environment);
